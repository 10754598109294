<template>
  <div class="product-child p-1 mx-1 mb-2" @click="emitClick">
    <div class="img">
      <div class="img-container">
        <img :src="img" width="140" height="120" />
      </div>
      <div class="title mt-1" :title="title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductListItemChild",
  props: {
    img: {
      type: String,
      default: "https://placehold.it/120x120"
    },
    title: {
      type: String,
      default: "-"
    },
    price: {
      type: String
    }
  },
  methods: {
    emitClick() {
      this.$emit("do-click");
    }
  }
};
</script>

<style lang="scss" scoped>
.product-child {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
}
.product-child:hover {
  border: 1px solid #006b90;
}

.title {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
}
</style>
