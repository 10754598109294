/**
 * interface Product {
 *  product_id: number;
 *  count: number;
 *  }
 **/

class ShoppingCart {
  #instance;
  #products;

  constructor() {
    if (this.#instance) {
      return this.#instance;
    }
    this.#instance = this;
    this.#products = this.#getSavedState() || [];
  }

  #getProductsFromOutStore = () => {
    return localStorage.getItem("cart_products");
  };

  #getSavedState = () => {
    const productsSource = this.#getProductsFromOutStore();
    if (productsSource) {
      return JSON.parse(this.#getProductsFromOutStore());
    }
    return [];
  };

  saveProductsToOutStore = () => {
    localStorage.setItem("cart_products", JSON.stringify(this.#products));
  };

  getProducts = () => {
    return this.#instance.#products;
  };

  addProduct = product => {
    this.#instance.#products.push(product);
  };

  removeProduct = productId => {
    const foundProductIdx = this.#instance.#products.findIndex(
      product => product.product_id === productId
    );
    if (foundProductIdx > -1) {
      this.#instance.#products.splice(foundProductIdx, 1);
    }
  };

  changeProductCount = (productId, count) => {
    const foundProduct = this.#instance.#products.find(
      product => product.product_id === productId
    );
    if (foundProduct) {
      foundProduct.count = count;
    }
  };
}

export default ShoppingCart;
