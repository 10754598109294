<template>
  <TemplateBlock
    mod-class
    content-class="shop-product"
    title-page="Интернет-магазин"
  >
    <div class="w-100 h-100 d-flex flex-column">
      <div>
        <ShopHeader />
      </div>
      <div class="scroll-container container flex-1">
        <div
          v-if="isProductInfoLoading || !currentProduct.technique"
          class="d-flex align-items-start justify-content-center mt-5"
        >
          <b-spinner></b-spinner>
        </div>
        <template v-else>
          <vuescroll>
            <div class="technique__title">
              {{ currentProduct.technique.title }}
            </div>
            <div class="scroll-container mt-3">
              <div
                class="mt-3 d-flex align-items-start justify-content-between"
              >
                <div class="left-side mr-2 info">
                  <div
                    class="box technique__images d-flex align-items-center justify-content-end"
                  >
                    <img
                      v-if="currentProduct.technique.files"
                      :src="currentProduct.technique.files[0].url"
                      height="250"
                      width="250"
                    />
                    <img v-else src="https://placehold.it/250x250" />
                  </div>
                </div>
                <div class="right-side flex-1 ml-2">
                  <div class="box technique__order full-width">
                    <div class="technique__price font-bold text_xl">
                      {{ currentProduct.price | money }}
                    </div>
                    <div
                      class="mt-3 d-flex align-items-center justify-content-center"
                    >
                      <button
                        v-if="!isSelected"
                        class="button mr-2 flex-1"
                        @click="toggleSelected"
                      >
                        Добавить в корзину
                      </button>
                      <button
                        v-else
                        class="button selected mr-2 flex-1"
                        @click="toggleSelected"
                      >
                        Удалить из корзины
                      </button>
                    </div>
                  </div>

                  <div class="mt-2 box technique__description">
                    <div class="font-bold mb-1">Описание товара:</div>
                    <div>{{ currentProduct.technique.description }}</div>
                  </div>

                  <div
                    class="technique-wrap mt-2 mb-6"
                    v-if="technique && category"
                  >
                    <div class="tehnique-block w-100">
                      <div
                        v-for="(param, index) of technique.parameters"
                        :key="index"
                        class="mb-1 alert alert-info"
                      >
                        <span class="font-weight-bold"
                          >{{ getTitle(param.code) }}:</span
                        >
                        {{ getParamValue(param.value) }}
                      </div>
                    </div>
                  </div>

                  <div v-if="childs && childs.length" class="box mt-2">
                    <div class="font-bold mb-1">
                      С этим товаром часто покупают:
                    </div>
                    <div class="d-flex childs-list-wrapper">
                      <ProductListItemChild
                        v-for="child in childs"
                        :key="child.id"
                        :title="child.title"
                        :img="child.files[0].url"
                        @do-click="goToChildPage(child.id)"
                      ></ProductListItemChild>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vuescroll>
        </template>
      </div>
    </div>
  </TemplateBlock>
</template>

<script>
import ShopHeader from "@/components/Shop/ShopHeader";
import RequestManager from "@/function/request/RequestManager";
import ShoppingCart from "@/utils/shopping-cart";
import eventBus from "@/utils/event-bus";
import TemplateBlock from "@/components/TepmplateBlock.vue";
import ProductListItemChild from "@/components/Shop/ProductListItemChild.vue";
import vuescroll from "vuescroll";

import { TechniqueApi, CategoryApi } from "@/function/request/apiV2";

export default {
  name: "ShopProduct",
  components: {
    ShopHeader,
    TemplateBlock,
    vuescroll,
    ProductListItemChild
  },
  data() {
    return {
      isProductInfoLoading: false,
      currentProduct: {},
      searchText: "",
      isSelected: false,
      products: [],
      technique: null,
      paramTitleIsLoading: false,
      category: null,
      childs: [],

      techniqueApi: new TechniqueApi(),
      categoryApi: new CategoryApi()
    };
  },
  methods: {
    goToShoppingCart() {
      this.doSaveCart();
      this.$router.push("/cart");
    },
    toggleSelected() {
      const foundProductIdx = this.products.findIndex(
        product => product.product_id === this.currentProduct.id
      );
      if (foundProductIdx > -1) {
        this.products.splice(foundProductIdx, 1);
      } else {
        const data = {
          product_id: this.currentProduct.id,
          count: 1
        };
        this.products.push(data);
      }
      this.isSelected = !this.isSelected;
      eventBus.$emit("cart-change", this.products);
    },
    async getCategory() {
      try {
        this.paramTitleIsLoading = true;
        const categoriesList = await this.categoryApi.api.categoriesList();
        this.category = categoriesList?.find(
          category => category.id === this.technique?.categoryId
        );
      } finally {
        this.paramTitleIsLoading = false;
      }
    },
    getParamValue(value) {
      const isStringValue = typeof value === "string";
      const isBooleanValue = typeof value === "boolean";

      if (isStringValue) {
        return !value ? "Не указано" : value;
      } else if (isBooleanValue) {
        return value ? "Да" : "Нет";
      }

      return value;
    },
    getTitle(code) {
      const param = this.category?.parameters?.find(el => el.code === code);
      return param ? param.title : code;
    },
    goToChildPage(childId) {
      console.log(childId);
    }
  },
  async mounted() {
    this.currentProduct = await RequestManager().productApi.getProduct(
      this.$route.params.id
    );
    const technique = await RequestManager().techniqueApi.techniqueGet(
      this.currentProduct.techniqueId
    );
    this.$set(this.currentProduct, "technique", technique);
    const foundProduct = new ShoppingCart()
      .getProducts()
      .find(product => product.product_id === this.currentProduct.id);
    this.isSelected = !!foundProduct;
    this.products = new ShoppingCart().getProducts();

    this.technique = await this.techniqueApi.getTechniqueFetch(
      this.currentProduct?.techniqueId
    );
    if (this.technique?.categoryId) {
      await this.getCategory();
    }
    const childsResponse = await RequestManager().techniqueApi.getTechniqueReferences(
      this.technique?.id,
      ["childs"]
    );
    this.childs = childsResponse.childs;
  }
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100% !important;
}
.pseudo-link {
  cursor: pointer;
}
.pseudo-link:hover {
  text-decoration: underline;
}
.font-bold {
  font-weight: 600;
}
.text_lg {
  font-size: 1.3em;
}
.text_xl {
  font-size: 1.5em;
}

.box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 15px;
}

.right-side {
  width: 400px;
}

.flex-1 {
  flex: 1;
}

.shop-page {
  background-color: #f9f9ff;
}

.button {
  font-size: 12px;
  background-color: #2c698c;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  font-weight: 600;
  border: 1px solid #2c698c;
  padding: 0.8em 1.2em;
  min-width: 120px;
}
.button.light {
  background-color: #ffffff;
  border: 1px solid rgba(0, 107, 144, 0.7);
  color: #2c698c;
}
.button.selected {
  background-color: #3bc915;
}
.button:hover {
  background-color: rgba(0, 107, 144, 0.7);
}
.button.light:hover {
  color: #ffffff;
}
.button.selected {
  background-color: rgba(15, 203, 25, 0.7);
  border: 1px solid rgba(15, 203, 25, 0.7);
}

.technique__title {
  color: #393952;
  font-size: 1.2em;
  font-weight: 500;
}

.__vuescroll {
  height: calc(100vh - 210px) !important;
}

.technique__order {
  position: sticky;
  top: 0;
  z-index: 1;
}

.childs-list-wrapper {
  flex-wrap: wrap;
}
</style>
