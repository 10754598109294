<template>
  <div
    class="filters d-flex align-items-center mb-2 mt-2 position-relative p-1"
  >
    <router-link to="/shop">
      <img height="56" :src="logoImg" class="pseudo-link filters__logo" />
    </router-link>
    <div
      class="filters__content full-width d-flex justify-content-between align-items-center"
    >
      <input
        v-if="currentRouteName === 'Shop'"
        v-model="searchText"
        type="text"
        class="form-control filters__search ml-5"
        placeholder="Модель принтера, МФУ"
        @keyup="doHandleSearch"
      />
      <div v-else class="filters__search"></div>
      <div
        v-if="categories && categories.length && currentRouteName === 'Shop'"
        class="d-flex align-items-center filters__categories"
      >
        <div
          class="pseudo-link menu-item mx-3 font-bold"
          :class="{ current: !currentCategoryId }"
          @click="doApplyCategory(undefined)"
        >
          Все
        </div>
        <div
          v-for="category in categories"
          :key="category.id"
          class="pseudo-link menu-item mx-3 font-bold"
          :class="{ current: currentCategoryId === category.id }"
          @click="doApplyCategory(category.id)"
        >
          {{ category.title }}
        </div>
      </div>
      <div class="button-wrapper">
        <router-link
          v-if="currentUser && currentUser.id"
          to="/chats"
          class="btn btn-second"
        >
          Получить консультацию менеджера
        </router-link>
        <router-link to="/contacts" v-else class="btn btn-second">
          Написать нам
        </router-link>
      </div>
    </div>

    <div class="shopping-cart pseudo-link" @click="goToShoppingCart">
      <div class="position-relative">
        <img :src="shopCartImg" class="shopping-cart-icon" height="48" />
        <span class="badge" v-if="products.length">
          {{ products.length }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImg from "@/resource/img/home-icon/printer.svg";
import ShopCartImg from "@/resource/img/shopping-cart-48.png";
import ShoppingCart from "@/utils/shopping-cart";
import eventBus from "@/utils/event-bus";
import RequestManager from "@/function/request/RequestManager";

export default {
  name: "ShopHeader",
  data() {
    return {
      logoImg: LogoImg,
      shopCartImg: ShopCartImg,
      searchText: "",
      shoppingCart: undefined,
      products: [],
      currentUser: {},
      categories: [],
      currentRouteName: undefined,
      currentCategoryId: undefined
    };
  },
  methods: {
    goToShoppingCart() {
      this.$router.push("/cart");
    },
    rereadSelectedProducts() {
      this.products = this.shoppingCart.getProducts();
    },
    setNewSCProducts(products) {
      this.products = products;
      localStorage.setItem("cart_products", JSON.stringify(products));
    },
    doApplyCategory(categoryId) {
      this.currentCategoryId = categoryId;
      eventBus.$emit("apply-category", categoryId);
    },
    doHandleSearch() {
      /* if (event.keyCode === 13) {
        eventBus.$emit("shop-search-enter", this.searchText);
      } else {
        eventBus.$emit("shop-search", this.searchText);
      } */
      eventBus.$emit("shop-search-filter", this.searchText);
    }
  },
  async created() {
    const currentUserSource = localStorage.getItem("currentUser");
    if (currentUserSource) {
      this.currentUser = JSON.parse(currentUserSource);
    }
    this.categories = await RequestManager().categoryApi.categoriesList();
    this.currentRouteName = this.$router.currentRoute.name;
  },
  mounted() {
    this.shoppingCart = new ShoppingCart();
    this.rereadSelectedProducts();
    eventBus.$on("cart-change", this.setNewSCProducts);
  }
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100% !important;
}
.pseudo-link {
  cursor: pointer;
}
.pseudo-link:hover {
  text-decoration: underline;
}
.font-bold {
  font-weight: 600;
}

.filters {
  min-height: 64px;

  .filters__search {
    max-width: 300px;
  }

  .menu-item {
    color: #006b90;
    font-size: 1.2em;
  }
  .menu-item.current {
    text-decoration: underline;
  }

  .button-wrapper {
    max-width: 160px;
  }
}

.shopping-cart {
  position: fixed;
  top: 110px;
  right: 20px;

  .badge_1 {
    background-color: red;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 600;
  }

  .badge {
    --badge-padding-x: 0.65em;
    --badge-padding-y: 0.35em;
    --badge-font-size: 0.75em;
    --badge-font-weight: 700;
    --badge-color: #fff;
    --badge-border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    display: inline-block;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    font-size: var(--badge-font-size);
    font-weight: var(--badge-font-weight);
    line-height: 1;
    color: var(--badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--badge-border-radius);
  }
}
</style>
